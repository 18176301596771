import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PlutoBrothersImage from '../../images/steven-forrest_jeffrey-green.jpg'

const WhatIsEA = () => (
  <Layout>
    <Seo title="Evolutionary astrology" />
    <h2>What Is Evolutionary Astrology?</h2>

    <p>Most people know something about astrology, but even in the astrology community many are unfamiliar with the terms "Evolutionary Astrology" (EA for short), the "Pluto Paradigm", the "Pluto School".</p>
    <p>What is more, people who try to learn EA or at least understand its basics can feel that it is inaccessible, couched in obscure language and secretive. So let's get this straight - what is EA, who founded it and what are some variants of EA as taught and practised today? </p>
    <h3>The basics</h3>
    <p>According to Steven Forrest, "Evolutionary Astrology is a name used to describe a style of astrology that understands each person's birth chart as a map of the soul's evolution". Right away we understand that EA brings with it an entire worldview. The understanding is that we are souls reincarnating over a series of lifetimes, learning lessons as we go, and that each life carries with it opportunities for growth, change and maturation - as well as repetition and stagnation. No judgment is implied in whether a person "succeeds" or "fails" to evolve during a particular lifetime. Circumstances can vary wildly and some people have a much easier time embracing a path of growth than others.</p>
    <p>To fully understand EA, we must understand what it is a reaction to. In traditional astrology, the planets were perceived to have "good" or "bad" placements in various signs: for example, Mars was considered exalted in Capricorn but debilitated in Cancer. Moreover, Mars and Saturn were considered malefic (negative) influences while Venus and Jupiter were seen as benefic (positive). This type of astrology produced the understanding that some planetary placements are better than others and - therefore - some charts are better, more desirable, than others. This form of astrology also tends to go hand in hand with the view that our lives are fated, that there is little we can do to change our circumstances. </p>
    <figure className="flex flex-col items-center">
      <img src={PlutoBrothersImage}></img>
      <figcaption>Steven Forrest (left) and Jeffrey Wolf Green (right).<br></br> Posted with the permission of Steven Forrest.</figcaption>
    </figure>
    <p>Early evolutionary astrologers like Steven Forrest and Jeffrey Wolf Green opposed this. They emphasized the importance of free will; they pointed out that a person's birthchart is a direct reflection of the evolutionary intent that the soul has set for the current life. We are all exactly where we need to be - what choices we make in response to our current predicament is up to us.</p>
    <p>Evolutionary astrology was formulated in the 1970's and started coming into its own in the 80's and 90's. While there were numerous astrologers involved in formulating EA as a concept, the "Pluto brothers" Jeffrey Wolf Green and Steven Forrest stand out as the most significant. Together, they defined a number of <a href="https://www.forrestastrology.com/pages/what-is-evolutionary-astrology">core perceptions</a> of what evolutionary astrology means:</p>
    <ol>
      <li>An acceptance of the fact that human beings incarnate in a succession of lifetimes.</li>
      <li>An acceptance of the fact that the birthchart reflects the evolutionary condition of the soul at the moment of incarnation.</li>
      <li>An acceptance of the fact that the birthchart reflects the evolutionary intentions of the soul for the present life.</li>
      <li>An acceptance of the fact that the circumstances of the present life, both materially and psychologically, do not arise randomly, but rather reflect the evolutionary intentions and necessities of the soul.</li>
      <li>An acceptance of the fact that human beings interact creatively and unpredictably with their birthcharts; that all astrological symbols are multi-dimensional and are modulated into material and psychic expression by the consciousness of the individual.</li>
      <li>An acceptance of the fact that human beings are responsible for the realities they experience, both internally and externally.</li>
      <li>A respectful intention to accept and support a person seeking astrological help, no matter the evolutionary state in which such an individual finds himself or herself.</li>
    </ol>
    <h3>The Pluto Paradigm</h3>
    <p>In the broad sense, evolutionary astrology is any growth-oriented form of astrology that is aligned with the core perceptions listed above. But when people speak of "EA", they often refer to the Pluto paradigm - a particular form of evolutionary astrology that treats Pluto as the root of the horoscope from which the meaning of all other astrological symbols in the chart is derived. This method was created by Jeffrey Green and described in his book <i>Pluto: The Evolutionary Journey of the Soul</i>. It could be summarized as follows:</p>
    <ul>
      <li>In any birth chart, Pluto corresponds to the complex of desires which the soul has desired to experience and comes into the current life with.</li>
      <li>The south node of the Moon and its ruler correspond to the ego structure that has been used to explore these soul desires on a conscious level.</li>
      <li>The point that is opposite Pluto in the chart - the Pluto Polarity Point (PPP) - corresponds to the deepest level of growth possible, on a soul level, in the current life.</li>
      <li>The north node of the Moon and its ruler correspond to the evolution on a personal, egoic level that is necessary for the PPP to be activated, and thus for deeper soul growth to occur.</li>
    </ul>
    <p>Together, these six chart points make up the main evolutionary axis of the chart. Other planets of great importance are planets squaring the nodal axis (so-called skipped steps), planets in conjunction to one of the nodes and planets in aspect to Pluto.</p>
    <p>Jeffrey Green was also a great opponent of what he termed "cookbook astrology": generalized descriptions of a planet in a sign or house taken out of context, for instance "Venus in Virgo seeks perfection in love". Venus or any other planet must be understood in relationship to the evolutionary axis; it is this axis that shows us the evolutionary intention for the current life and which can therefore tell us <i>why</i> Venus is where it is. Moreover, when interpreting a planet in a chart, the house position takes precedence over the sign. Interpreting a planet in a sign only is a bit like reading the second half of a sentence; without the first half, we don't know what the second half is referring to.</p>

    <h3>Branching out</h3>
    <p>During the 1990's and early 00's, Jeffrey Green held many courses and lectures until his retirement and disappearance in 2004. Many of his students became practising astrologers in their own right. Today, there are more and more astrologers who were not his direct students but still draw on the Pluto paradigm, sometimes bringing in new ideas while staying true to the underlying spirit of EA. I believe the foremost of these is my own teacher Michael de Baker, who has advanced the field through his rigorous, methodical approach to chart analysis, and through his emphasis on the need for astrologers to develop therapeutic skills. Much of his material is only available through his online video courses, but he has also released the books <i>Intercepted Signs</i>, <i>Chiron: Trauma Key</i> and <i>Same Soul</i> which I would certainly recommend for advanced students of EA.</p>
    <p>There has also been an intermarriage between EA and regression therapy, a form of therapy that can help people remember the original source of ongoing trauma, even if that source lies in a past life. Here I would recommend the book <i>Understanding Karmic Complexes</i> by Patricia Walsh, where she connects the charts of several of her clients to past-life memories that were uncovered during regression.</p>
    <p>(See the end of this article for a full list of recommended resources.) </p>

    <h3>Are you new to EA?</h3>
    <p>When I started studying EA, it seemed like I had entered a strange world. There was something incredibly profound about Jeffrey Green's teachings, while at the same time many facets of EA were hard to understand. I don't think this is necessarily a problem: what is profound is not easily spelled out in clear, unambiguous language. Nevertheless, students of EA cannot be expected to learn EA on their own - they need a teacher and they need hours upon hours of practice under the guidance of that teacher. It is also important that this practice be in relation to people that are known to you, be it celebrities, personal clients or yourself. </p>
    <p>If you are new to EA and don't know where to start, I would be more than happy to help you find your own way through this vast landscape. While it is no secret that I recommend the online course modules of Michael de Baker, I am open to other options as well. Which option is best for you could depend on:</p>
    <ul>
      <li>How new you are to astrology in general</li>
      <li>How important it is for you to stay close to the original Pluto paradigm as formulated by Jeffrey Green</li>
      <li>Which teacher and teaching style you personally connect with</li>
    </ul>

    <p><Link to="/contact">Contact me</Link> and I will do my best to help you find your way in the world of EA. Or you can simply proceed on your own by looking at my list of <Link to="/evolutionary-astrology/resources">recommended EA resources</Link>.</p>

    <Link to="/evolutionary-astrology">Back to Articles</Link>

  </Layout>
)

export default WhatIsEA